<template>
    <div class="basic_data_edit">
        <AssessHeader :currentStep="3" :dataSource="assessHeaderData"></AssessHeader>
        <div class="data_content data-content-form scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button :snButton="snButton" @handleChange="handleChange"></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form class="demo-form-inline form_row" ref="formdata" :inline="true" :model="formdata.saveJson" size="small" label-width="200px" :rules="rules">
                <div class="step-title">Step1 基本信息配置</div>
                <div class="step-content">
                    <el-form-item label="评估配置名称" prop="PZMC">
                        <el-input v-model="formdata.saveJson.PZMC" placeholder="" :maxlength="50" onkeydown="if(event.keyCode==32) return false" :disabled="true"></el-input>
                    </el-form-item>
                    <el-form-item label="评估状态" prop="ZT">
                        <el-select v-model="formdata.saveJson.ZT" placeholder="请选择评估状态" filterable :disabled="!isEdit">
                            <el-option v-for="(item,index) in pgztList" :key="index" :label="item.Name" :value="item.Code"></el-option>
                        </el-select>
                    </el-form-item>
                </div>

                <div class="step-title">Step2 评估对象及评估方法配置</div>
                <div class="step-content">
                    <el-form-item label="评估记录" prop="PGJLID">
                        <el-select v-model="formdata.saveJson.PGJLID" placeholder="请选择评估记录" filterable :disabled="!isEdit" @change="handlePgjlChanged">
                            <el-option v-for="(item,index) in pgjlList" :key="index" :label="item.PGJLMC" :value="item.ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评估方式" prop="PGFS">
                        <el-select v-model="formdata.saveJson.PGFS" placeholder="请选择评估方式" filterable :disabled="!isEdit" @change="handlePgfsChanged">
                            <el-option v-for="(item,index) in pgfsList" :key="index" :label="item.Name" :value="item.Code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评估人员" prop="PGRID">
                        <el-select v-model="formdata.saveJson.PGRID" placeholder="请选择评估人员" filterable :disabled="!isEdit || formdata.saveJson.PGFS===''">
                            <el-option v-for="(item,index) in pgrList" :key="index" :label="item.NAME" :value="item.ID"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="评估周期" prop="ZQ">
                        <el-input class="zq-input" v-model="formdata.saveJson.ZQ" placeholder="请输入评估周期" :maxlength="2" onkeyup="this.value=this.value.replace(/[^\d.]/g,'');" onkeydown="if(event.keyCode==32) return false" :disabled="!isEdit"></el-input>
                        <el-radio-group v-model="formdata.saveJson.ZQDW">
                            <el-radio label="1">月</el-radio>
                            <el-radio label="0">年</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </div>

                <div class="step-title">Step3 评估数据配置</div>
                <div class="step-content">
                    <div class="grid-group">
                        <div class="grid-item">
                            <CommonGrid :dataSource="monitorData" @handleSelectionChange="handleMonitorSelectionChange"></CommonGrid>
                        </div>
                        <div class="grid-item">
                            <!-- <CommonGrid :dataSource="assessData" @handleSelectionChange="handleAssessSelectionChange"></CommonGrid> -->
                            <AssessDataGrid :dataSource="assessData" @handleCheckboxChanged="handleCheckboxChanged"></AssessDataGrid>
                        </div>
                        <div class="grid-item">
                            <CommonGrid :dataSource="relatedData" @handleSelectionChange="handleRelatedSelectionChange"></CommonGrid>
                        </div>
                    </div>
                </div>
            </el-form>
        </div>
    </div>
</template>

<script>
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import AssessHeader from './components/assess_header'
import CommonGrid from './components/common_grid'
import AssessDataGrid from './components/assess_data_grid'
export default {
    name: "assess_config_form",
    mixins: [infoMixin],
    data() {
        return {
            notNeedFile: true,
            snButton: snButton,
            formdata: {
                saveJson: {
                    PZMC: "评估配置",
                    ZT: "",
                    PGJLID: "",
                    PGFS: "",
                    PGRID: "",
                    ZQ: null,
                    ZQDW: "1",
                    CJRID: localStorage.userId,
                    RWSFBHDQZQ: "1",
                },
                heritageId: "",
                userName: "",
                userId: ""
            },
            pgztList: [], //评估状态列表
            pgjlList: [], //评估记录列表
            pgfsList: [], //评估方式列表
            pgrList: [], //评估人列表

            rules: {
                ZT: [
                    { required: true, message: '请选择评估状态', trigger: 'change' }
                ],
                PGJLID: [
                    { required: true, message: '请选择评估记录', trigger: 'change' }
                ],
                PGFS: [
                    { required: true, message: '请选择评估方式', trigger: 'change' }
                ],
                PGRID: [
                    { required: true, message: '请选择评估人员', trigger: 'change' }
                ],
                ZQ: [
                    { required: true, message: '请输入评估周期', trigger: 'blur' },
                ],
            },
            monitorData: {
                type: '',
                gridIndex: '1',
                title: '监测数据',
                JcdxInfos: []
            },
            assessData: {
                type: '',
                gridIndex: '2',
                title: '评估数据',
                JcdxInfos: [],
                Pgdxid: '',
            },
            relatedData: {
                type: '',
                gridIndex: '3',
                title: '相关数据',
                JcdxInfos: []
            },
            JcdxInfos: [],
            JcdxInfosMonitor: [], //监测数据选择的值们
            JcdxInfosAssess: [], //评估数据选择的值们
            JcdxInfosRelated: [], //相关数据选择的值们
            assessHeaderData: {
                title: '请创建评估配置',
                content: '请选择评估记录，配置评估方式、评估数据等实现评估配置。',
            },
            pgjlLable: '', //评估记录名称
            pgfsLable: '', //评估方式名称
            JcdxInfos2: [],
        };
    },
    components: {
        AssessHeader,
        CommonGrid,
        AssessDataGrid
    },
    watch: {
    },
    mounted() {
        this.snButton.buttonData[1].isShow = false;
        this.getPgztList();
        this.getPgjlList();
        this.getPgfsList();
        // this.getPgrList();
        if(this.type === '0'){
            this.formdata.saveJson.ZT = '1';
            this.monitorData = {
                type: this.type,
                gridIndex: '1',
                title: '监测数据',
                JcdxInfos: []
            };
            this.assessData = {
                type: this.type,
                gridIndex: '2',
                title: '评估数据',
                JcdxInfos: [],
                Pgdxid: '',
            }
            this.relatedData = {
                type: this.type,
                gridIndex: '3',
                title: '相关数据',
                JcdxInfos: []
            }
        }

    },
    methods: {
        ...mapActions([
            'AddPgpzInfo', //新建/修改评估配置
            "GetEnumItem",
            "GetPgjlInfoList", //获取评估记录信息列表
            "GetPgryList", //获取评估人员列表
            'GetPgpzInfoDetail', // 获取评估配置详情
        ]),
        // 获取评估状态列表
        async getPgztList(){
            this.pgztList = [];
            let result = await this.GetEnumItem({
                Enumid: "0DF6922D-9505-4F77-B0CA-BE09C1BA0203"
            })
            if(result && result.length>0){
                result.forEach(item=>{
                    if(item.Code!=='3'){
                        this.pgztList.push(item);
                    }
                })
            }
        },
        // 获取评估记录列表
        async getPgjlList(){
            this.pgjlList = [];
            let result = await this.GetPgjlInfoList({
                pageSize: 99999999
            })
            this.pgjlList = result.RESULT;
        },
        // 获取评估方式列表
        async getPgfsList() {
            let _this = this;
            this.pgfsList = [];
            let result = await this.GetEnumItem({
                Enumid: "11a0b052-e2a7-4c84-b0c5-d1befdbcb455"
            });
            this.pgfsList = result;

            if(this.type === '1'){
                this.pgfsLable = '';
                if(this.pgfsList && this.pgfsList.length>0 && this.formdata.saveJson.PGFS){
                    this.pgfsList.forEach(item=>{
                        if(item.Code === _this.formdata.saveJson.PGFS){
                            _this.pgfsLable = item.Name;
                        }
                    })
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        handlePgjlChanged(val){
            let _this = this;
            this.pgjlLable = '';
            if(this.pgjlList && this.pgjlList.length>0){
                this.pgjlList.forEach(item=>{
                    if(item.ID === _this.formdata.saveJson.PGJLID){
                        _this.pgjlLable = item.PGJLMC;
                        _this.formdata.saveJson.PGDXLX = item.PGDXLX;
                        if (this.type === '0') {
                            this.monitorData = {
                                type: this.type,
                                gridIndex: '1',
                                title: '监测数据',
                                isPgjl: true,
                                JcdxInfos: item.JCDXIDS
                            };
                            this.assessData = {
                                type: this.type,
                                gridIndex: '2',
                                title: '评估数据',
                                JcdxInfos: this.JcdxInfos2,
                                Pgdxid: item.PGDXID,
                            }
                        }
                    }
                })
                if(this.pgjlLable){
                    this.setPgpzmc();
                }
            }
        },
        // eslint-disable-next-line no-unused-vars
        handlePgfsChanged(val){
            let _this = this;
            this.formdata.saveJson.PGRID = '';
            this.pgfsLable = '';
            if(this.pgfsList && this.pgfsList.length>0){
                this.pgfsList.forEach(item=>{
                    if(item.Code === _this.formdata.saveJson.PGFS){
                        _this.pgfsLable = item.Name;
                    }
                })
                if(this.pgfsLable){
                    this.setPgpzmc();
                }
            }
            this.getPgrList();
        },
        setPgpzmc(){
            this.formdata.saveJson.PZMC = `${this.pgjlLable}${this.pgfsLable}评估配置`;
        },
        // 获取评估人列表
        async getPgrList() {
            this.pgrList = [];
            let result = await this.GetPgryList({
                Rylx: this.formdata.saveJson.PGFS
            });
            this.pgrList = result;
        },
        async getFromData() {
            let _this = this;
            if (_this.$route.query.data) {
                let queryData = JSON.parse(_this.$route.query.data)
                let pgpzID = queryData.ID;
                let result = await _this.GetPgpzInfoDetail({pgpzID});

                // Object.assign(_this.formdata.saveJson, queryData);
                if(result) {
                    Object.assign(_this.formdata.saveJson, result);
                }

                _this.getPgrList();

                let JcdxInfos1 = [];
                _this.JcdxInfos2 = [];
                let JcdxInfos3 = [];

                if(_this.formdata.saveJson.JCXLIST && _this.formdata.saveJson.JCXLIST.length>0){
                    _this.formdata.saveJson.JCXLIST.forEach(item0=>{
                        if(item0.SJLY === '0'){
                            JcdxInfos1.push(item0.JCDXID);
                        }else if(item0.SJLY === '1'){
                            _this.JcdxInfos2.push(item0.MC);
                        }else if(item0.SJLY === '2'){
                            JcdxInfos3.push(item0.JCDXID);
                        }
                    })
                }

                _this.monitorData = {
                    type: _this.type,
                    gridIndex: '1',
                    title: '监测数据',
                    JcdxInfos: JcdxInfos1
                };
                _this.relatedData = {
                    type: _this.type,
                    gridIndex: '3',
                    title: '相关数据',
                    JcdxInfos: JcdxInfos3
                }

                if(_this.type !== '0'){
                    _this.pgjlLable = '';
                    if(_this.pgjlList && _this.pgjlList.length>0 && _this.formdata.saveJson.PGJLID){
                        _this.pgjlList.forEach(item=>{
                            if(item.ID === _this.formdata.saveJson.PGJLID){
                                _this.pgjlLable = item.PGJLMC;
                                _this.formdata.saveJson.PGDXLX = item.PGDXLX;
                                _this.assessData = {
                                    type: _this.type,
                                    gridIndex: '2',
                                    title: '评估数据',
                                    JcdxInfos: _this.JcdxInfos2,
                                    Pgdxid: item.PGDXID,
                                }
                            }
                        })
                    }
                }
            }
        },
        handleMonitorSelectionChange(val){
            this.JcdxInfosMonitor = [];
            if(val && val.length>0){
                val.forEach(item=>{
                    this.JcdxInfosMonitor.push({
                        Jcdxid: item.ID,
                        Sjly: '0'
                    })
                })
            }
        },
        handleCheckboxChanged(val){
            this.JcdxInfosAssess = [];
            if(val && val.length>0){
                val.forEach(item=>{
                    this.JcdxInfosAssess.push({
                        Jcdxid: item.JCDXID,
                        YCYSBM: item.YCYSBM,
                        Sjly: '1'
                    })
                })
            }
        },
        handleAssessSelectionChange(val){
            this.JcdxInfosAssess = [];
            if(val && val.length>0){
                val.forEach(item=>{
                    this.JcdxInfosAssess.push({
                        Jcdxid: item.ID,
                        Sjly: '1'
                    })
                })
            }
        },
        handleRelatedSelectionChange(val){
            this.JcdxInfosRelated = [];
            if(val && val.length>0){
                val.forEach(item=>{
                    this.JcdxInfosRelated.push({
                        Jcdxid: item.ID,
                        Sjly: '2'
                    })
                })
            }
        },
        async saveData() {
            if(this.formdata.saveJson.ZQ == 0){
                this.$message({
                    type: 'warning',
                    message: '请填写有效周期'
                });
            }else if(this.formdata.saveJson.ZQ.indexOf(".") !== -1){
                this.$message({
                    type: 'warning',
                    message: '请填写有效周期'
                });
            }else{
                this.JcdxInfos = [];
                if(this.JcdxInfosMonitor && this.JcdxInfosMonitor.length>0){
                    this.JcdxInfosMonitor.forEach(item1=>{
                        this.JcdxInfos.push(item1)
                    })
                }
                if(this.JcdxInfosAssess && this.JcdxInfosAssess.length>0){
                    this.JcdxInfosAssess.forEach(item2=>{
                        this.JcdxInfos.push(item2)
                    })
                }
                if(this.JcdxInfosRelated && this.JcdxInfosRelated.length>0){
                    this.JcdxInfosRelated.forEach(item3=>{
                        this.JcdxInfos.push(item3)
                    })
                }
                if(this.JcdxInfos && this.JcdxInfos.length>0){
                    let res = await this.AddPgpzInfo({
                        Info: JSON.stringify(this.formdata.saveJson),
                        JcxInfos: JSON.stringify(this.JcdxInfos)
                    });
                    if (res.ISSUCCESS) {
                        this.$message({
                            type: 'success',
                            message: res.RESULTDESCRIPTION
                        });
                        this.returnList();
                    } else {
                        this.$message({
                            type: 'error',
                            message: res.RESULTDESCRIPTION
                        });
                    }
                }else{
                    this.$message({
                        type: 'warning',
                        message: '请选择评估数据'
                    });
                }
            }
        },
    }
};
</script>
<style lang="scss" scoped>
.data-content-form {
    width: 100%;
    height: calc(100% - 150px)!important;
    .step-title{
        padding: 20px 0 0 20px;
        font-size: 15px;
        color: #2a3987;
        font-weight: bold;
    }
    .step-content{
        margin-top: 8px;
        padding: 14px 40px;
        background: #fff;
        .zq-input{
            width: calc(100% - 160px);
            margin-right: 30px;
        }
        .grid-group{
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }
        .grid-item{
            width: 30%;
        }
    }
}
</style>