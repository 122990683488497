<template>
    <div :id="`gridId${dataSource.gridIndex}`">
        <div class="aa-common-search">
            <div class="search-title">{{dataSource.title}}</div>
            <el-button class="search-btn" type="primary">查询</el-button>
            <el-input class="search-input" clearable v-model="keyWord" @change="handleSearchChanged(dataSource.gridIndex)"></el-input>
        </div>
        <div class="adg-border">
            <div class="adg-table-header">
                <el-checkbox v-show="tableData && tableData.length > 0" :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>
                <span class="adg-table-header-text">评估对象</span>
            </div>
            <div class="adg-table-content scrollbar scrollbarX">
                <el-checkbox-group class="adg-table-row" v-show="tableData && tableData.length > 0" v-model="checkList" v-for="(item,index) in tableData" :key="index" @change="handleCheckboxChanged">
                    <div>
                        <el-checkbox :label="item.MC" :value="item.ID" :title="item.MC"></el-checkbox>
                    </div>
                </el-checkbox-group>
                <div class="adg-table-nodata" v-show="!tableData || tableData.length === 0">暂无数据</div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    data() {
        return {
            tableData: [],
            keyWord: '',
            checkList: [],
            isIndeterminate: false,
            checkAll: false,
        }
    },
    props: {
        dataSource: {
            type: Object,
            default: () => { }
        }
    },
    watch: {
        dataSource() {
            this.handleDataSource();
        }
    },
    mounted() {
        // this.handleDataSource();
    },
    methods: {
        ...mapActions([
            'GetPgsjList', //获取评估数据列表
        ]),
        handleDataSource() {
            this.initData();
            switch (this.dataSource.gridIndex) {
                case '2':
                    this.getTableData2('');
                    break;
            }
        },
        handleSearchChanged(val) {
            this.initData();
            switch (val) {
                case '2':
                    this.getTableData2(this.keyWord);
                    break;
            }
        },
        initData() {
            this.tableData = [];
        },
        async getTableData2(Key) {
            let _this = this;
            _this.checkList = [];
            let Pgdxid = '';
            Pgdxid = _this.dataSource.Pgdxid;
            if (!Pgdxid) return;

            let result = await _this.GetPgsjList({
                Key: Key, //String 内存放评估数据对象名称
                Pgdxid: Pgdxid, //string 评估对象ID
            });

            if (result) {
                let rowObj = {
                    JCDXID: result.JCDXID || '',
                    MC: result.MC || '',
                    YCYSBM: result.YCYSBM || '',
                };
                _this.tableData.push(rowObj);
                if (result.JCDXLIST && result.JCDXLIST.length > 0) {
                    _this.handleTableData2(result, result.JCDXLIST);
                }

                if (_this.dataSource.type === '0') {
                    // 全选
                    _this.handleCheckAllChange(true);
                    _this.checkAll = true;
                } else {
                    _this.checkList = _this.dataSource.JcdxInfos;
                    _this.handleCheckboxChanged(_this.dataSource.JcdxInfos);
                }
            }
        },
        handleTableData2(val, child) {
            if (child && child.length > 0) {
                child.forEach(item => {
                    let rowObj = {
                        JCDXID: item.JCDXID || '',
                        MC: item.MC || '',
                        YCYSBM: item.YCYSBM || '',
                    };
                    this.tableData.push(rowObj);
                })
            }
        },
        handleCheckAllChange(val) {
            let queryArr = [];
            if (val) {
                if (this.tableData && this.tableData.length > 0) {
                    this.tableData.forEach(item1 => {
                        this.checkList.push(item1.MC);
                        queryArr.push(item1);
                    })
                }
            } else {
                this.checkList = [];
            }
            this.isIndeterminate = false;
            this.$emit("handleCheckboxChanged", queryArr)
        },
        handleCheckboxChanged(val) {
            let checkedCount = val.length;
            this.checkAll = checkedCount === this.tableData.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.tableData.length;

            let queryArr = [];
            if (val && val.length > 0) {
                val.forEach(item => {
                    if (this.tableData && this.tableData.length > 0) {
                        this.tableData.forEach(item1 => {
                            if (item1.MC === item) {
                                queryArr.push(item1);
                            }
                        })
                    }
                })
            }
            this.$emit("handleCheckboxChanged", queryArr)
        },
    }
}
</script>
<style lang="scss" scoped>
.aa-common-search {
    position: relative;
    width: 100%;
    margin: 16px 0;
    .search-title {
        height: 30px;
        line-height: 30px;
        color: #2a3987;
        font-size: 15px;
    }
    .search-btn {
        position: absolute;
        top: 1px;
        right: 0;
        height: 28px;
    }
    .search-btn.el-button--primary {
        color: #FFF;
        background-color: #2a63d5;
        border-color: #2a63d5;
    }
    .search-input {
        position: absolute;
        top: 0;
        right: 80px;
        width: 200px;
    }
    .search-btn.el-button {
        line-height: 0px;
    }
}

.adg-border {
    width: 100%;
    height: 100%;
    border: 1px solid #ebeef5;
    border-bottom: none;
    .adg-table-header {
        width: 100%;
        height: 44px;
        line-height: 44px;
        padding: 0 16px;
        border-bottom: 1px solid #ebeef5;
        color: #fff;
        background: #a8b0c4;
        .adg-table-header-text {
            padding-left: 10px;
            font-weight: bold;
        }
    }
    .adg-table-content {
        max-height: 454px;
        overflow-y: auto;
        .adg-table-nodata {
            height: 66px;
            line-height: 66px;
            text-align: center;
            border-bottom: 1px solid #ebeef5;
        }
        .adg-table-row:first-child {
            padding: 0 16px;
        }
        .adg-table-row {
            height: 44px;
            line-height: 44px;
            padding: 0 40px;
            border-bottom: 1px solid #ebeef5;
        }
    }
}
</style>
